import * as React from "react"
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import {GlobalStyles} from "../components/GlobalStyles";
import {Container, StyledFlowerLeft, StyledImg, StyledLogo} from "../components/container";

// markup
const IndexPage = () => {
    const data = useStaticQuery(graphql`
    query HeaderQuery {
      logo: file(relativePath: { eq: "griet-piet-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, 
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF])        }
      }
      candle1: file(relativePath: { eq: "griet-piet-mockup-1.png"} ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, 
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF])
        }
      }
      left1: file(relativePath: { eq: "griet-piet-left-1.png"} ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, 
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF])
        }
      }
      right1: file(relativePath: {eq: "griet-piet-right-top1.png"}) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, 
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)
    return (
        <main style={{margin: 0, height: '100%'}}>
            <GlobalStyles />
            <title>Griet + Piet | opent binnenkort</title>
            <Container>
                <StyledLogo image={data.logo.childImageSharp.gatsbyImageData} alt="Griet + Piet" />
                <div>
                    Opent binnenkort hier haar deuren
                </div>
                <div style={{marginTop: 5}}>
                    <a href={"https://www.instagram.com/griet.en.piet/"}>Instagram</a>
                    <span> | </span>
                    <a href={"https://etsy.com/shop/grietenpiet"}>Etsy</a>
                    <span> | </span>
                    <a href={"https://www.facebook.com/Griet-Piet-107943151738610"}>Facebook</a>
                </div>
            </Container>
            <StyledImg image={data.candle1.childImageSharp.gatsbyImageData} alt={"Kaars Knuffel in een potje"}/>
            <StyledFlowerLeft alt={"flowers"} image={data.left1.childImageSharp.gatsbyImageData} />
            <GatsbyImage style={{position: 'absolute', right: 0, top: 0, maxWidth: 300}} alt={"flowers"}
                         image={data.right1.childImageSharp.gatsbyImageData} />
        </main>
    )
}

export default IndexPage
