import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";

export const Container = styled.div`
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 100;
    height: 90vh;
    padding: 10px;
    background: radial-gradient(circle, rgba(251, 250, 235,1) 0%, rgba(251, 250, 235,0) 60%);
`
export const StyledImg = styled(GatsbyImage)`
    @media only screen and (max-width: 600px) {
        width: 70%;
        right: -40px;
        bottom: 30px;
    }
    width: 400px; 
    position: absolute !important;
    right: 10%;
    bottom: 10%;
    z-index: -1;
`
export const StyledFlowerLeft = styled(GatsbyImage)`
    @media only screen and (max-width: 600px) {
        max-width: 250px;
    }
    position: absolute !important;
    left: 0;
    bottom: 0;
    max-width: 300px;
`
export const StyledLogo = styled(GatsbyImage)`
    max-width: 800px;
`